import React from 'react';
import * as Sentry from '@sentry/react';

import { SENTRY_DSN, SENTRY_ENVIRONMENT, SENTRY_RELEASE } from '@/config';

Sentry.init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENVIRONMENT,
  release: SENTRY_RELEASE
});

export interface Props {}

const ErrorBoundary: React.FC<Props> = ({ children }) => (
  <Sentry.ErrorBoundary showDialog>{children}</Sentry.ErrorBoundary>
);

export default ErrorBoundary;
